import React from "react";

const Showcase = () => {
  return (
    <>
      <section class="showcase ">
        <div class="container ">
          <div class="showcase-content ">
              
          </div>
        </div>
      </section>
    </>
  );
};

export default Showcase;
